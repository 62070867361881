import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";



const Identification = () => {
  return (
    <Layout>
      <div className="container">
        <h1 className="text-center mt-60 mb-30">Идентификация продукции</h1>
        <div className="corpus__block">
          <div className="corpus">
            <div className="corpus__text">
              <h2>DENDOR НА КОРПУСЕ</h2>
              <p>На корпусе продукции имеется отлитая надпись «DENDOR»</p>
            </div>
            <div className="corpus__img mt-30">
              <StaticImage
                className="service__image"
                alt="Идентификация продукции Dendor"
                src="../images/identification.jpg"
                width={350}
                placeholder="#fff"
              />
            </div>
          </div>
          <div className="corpus mt-30">
            <div className="corpus__text">
              <h2>ШИЛЬДА С QR-КОДОМ</h2>
              <p>
                Вся продукция DENDOR имеет заводскую шильду установленного
                образца с уникальным серийным номером и QR-кодом
              </p>
            </div>
            <div className="corpus__img mt-15">
              <StaticImage
                className="service__image"
                alt="ШИЛЬДА С QR-КОДОМ Dendor"
                src="../images/ind2.jpg"
                width={650}
                placeholder="#fff"
              />
            </div>
          </div>
          <div className="corpus mt-30">
            <div className="corpus__text">
              <h2>ПАСПОРТ НА ИЗДЕЛИЕ И СЕРТИФИКАТ</h2>
              <p>
                Вся продукция DENDOR комплектуется паспортами на изделие,
                сертификатами соответствия, экспертным заключением и другими
                сопроводительными документами установленного образца.
              </p>
            </div>
            <div className="corpus__img mt-15">
              <StaticImage
                className="service__image"
                alt="ПАСПОРТ НА ИЗДЕЛИЕ И СЕРТИФИКАТ"
                src="../images/identification5.jpg"
                width={350}
                placeholder="#fff"
              />
            </div>
          </div>
          <div className="corpus mt-30">
            <div className="corpus__text">
              <a
                className="corpus__btn"
                href="https://dendor.ru/wp-content/uploads/private1.php"
              >
                Технический паспорт на арматуру DENDOR
              </a>
              <p>
                Подробную информацию о продукции DENDOR, подлинности,
                комплектации, гарантии и т.п., можно уточнить в отделе контроля
                любым удобным для Вас способом, указанным на странице контакты
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Identification;
